

.custom-shape-divider-top-1651085402 {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    overflow: hidden;
    line-height: 0;
    transform: rotate(180deg);
}

.custom-shape-divider-top-1651085402 svg {
    position: relative;
    display: block;
    width: calc(100% + 1.3px);
    height: 120px;
    transform: rotateY(180deg);
    border: none
}

.custom-shape-divider-top-1651085402 .shape-fill {
    fill: #FFFFFF;
}

/** For mobile devices **/
@media (max-width: 767px) {
    .custom-shape-divider-top-1651085402 svg {
        width: calc(100% + 1.3px);
        height: 47px;
    }
}